import Layout from "../../components/layout"
import React from "react"
import SEO from "../../components/seo"

function LoweRekrutacja() {
  return (
    <Layout>
      <SEO title="LOWE Rekrutacja - ZSP Nidek" />
      <h1>LOWE Rekrutacja</h1>
      <a
        href="../../../documents/lowe/rekrutacja/LOWE_REGULAMIN_REKRUTACJI_Gmina_Wieprz.pdf"
        download="LOWE_REGULAMIN_REKRUTACJI_Gmina_Wieprz.pdf"
      >
        Lowe regulamin rekrutacji - Gmina Wieprz (184KB | pdf)
      </a>
      <a
        href="../../../documents/lowe/rekrutacja/Formularz_rekrutacyjny_LOWE_Gmina_Wieprz.docx"
        download="Formularz_rekrutacyjny_LOWE_Gmina_Wieprz.docx"
      >
        Formularz rekrutacyjny LOWE Gmina Wieprz (77KB | docx)
      </a>
      <a
        href="../../../documents/lowe/rekrutacja/Formularz_rekrutacyjny_LOWE_Gmina_Wieprz.pdf"
        download="Formularz_rekrutacyjny_LOWE_Gmina_Wieprz.pdf"
      >
        Formularz rekrutacyjny LOWE Gmina Wieprz (221KB | pdf)
      </a>
    </Layout>
  )
}

export default LoweRekrutacja
